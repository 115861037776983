<template>
  <div class="tour-form-success">
    <h2 class="tour-form-success__title tt-uppercase">
      Вы зарегистрированы на мероприятие!
    </h2>
    <p class="tour-form-success__text">
      Письмо-подтверждение отправлено на вашу почту. Если вы
не сможете посетить мероприятие, пожалуйста, сообщите об этом в ответ на письмо-приглашение. Организаторы могут отказать
в записи на последующие события тем, кто записался, но не явился на мероприятие, не отменив свою запись.
    </p>
    <CustomButton
      class="tour-from-success__btn"
      theme="primary"
      size="md"
      @click="onClick"
    >
      Хорошо
    </CustomButton>
  </div>
</template>

<script>
export default {
  methods: {
    onClick() {
      this.$root.$refs.customModal.closeModal()
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.tour-form-success';

#{$b} {

  // .tour-form-success__title
  &__title {
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 44px;
    line-height: 100%;
    margin: 0 0 24px 0;
    padding-right: 30px;

    @include mobile {
      font-size: 28px;
      margin-bottom: 16px;
    }
  }

  // .tour-form-success__text
  &__text {
    font-family: $font-family-inter;
    font-size: 16px;
    line-height: 1.3;
    font-weight: normal;
    color: $black-true;
    margin: 0 0 32px 0;

    @include mobile {
      margin-bottom: 16px;
    }
  }
}
</style>
