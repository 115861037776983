<template>
  <div class="u-excursions-wrapper">
    <slot/>
  </div>
</template>

<script>
export default {
  props: {
    submitTourFormUrl: {
      type: String,
      default: '/'
    },
    categoryOptions: {
      type: Array,
      default: () => []
    }
  },
  provide() {
    return {
      submitUrl: this.submitTourFormUrl,
      categoryOptions: this.categoryOptions,
    }
  },
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-excursions-wrapper';

#{$b} {
  position: relative;
  //overflow: hidden;

  .about-main__text {
    font-size: 24px;
    padding: 26px 0 32px 0;
    border-bottom: 1px solid $black-true;
    margin-bottom: 31px;

    @include mobile {
      font-size: 16px;
      padding: 2px 0 0 0;
      border-bottom: none;
      margin-bottom: 49px;
    }
  }
}
</style>
