<template>
  <div class="science-playground-filter">
    <div class="science-playground-filter__inner">
      <div class="u-g-container">
        <div class="science-playground-filter__top">
          <div class="science-playground-filter__top-title science-playground-filter__title title-xl" v-html="tabsTitle" />
          <div v-if="!breakpoint.isMobile" class="science-playground-filter__tabs">
            <div
              class="science-playground-filter__tab science-playground-filter__btn"
              :class="{'science-playground-filter__btn--is-active' : isActiveItem('theme', allSelectedNameId)}"
              @click="selectItem('theme', allSelectedNameId)"
            >
              Все
            </div>
            <div
              v-for="tab in tabs"
              :key="`science-playground-filter__tab-${tab.id}`"
              class="science-playground-filter__tab science-playground-filter__btn"
              :class="{'science-playground-filter__btn--is-active' : isActiveItem('theme', tab.id)}"
              @click="selectItem('theme', tab.id)"
            >
              {{ tab.name }}
            </div>
          </div>
          <div v-else class="science-playground-filter__dropdown">
            <CustomDropdown
              v-model="activeFilters.theme"
              :options="tabs"
              :multiply="true"
              placeholder="Весь контент"
              :closeAfterSelect="false"
              @input="$emit('filter', activeFilters)"
            />
          </div>
        </div>
        <div class="science-playground-filter__bottom">
          <div class="science-playground-filter__bottom-title science-playground-filter__title title-xl" v-html="tagsTitle" />
          <div class="science-playground-filter__tags">
            <div class="science-playground-filter__tags-inner">
              <div class="science-playground-filter__tags-item">
                <div
                  class="science-playground-filter__tag science-playground-filter__btn"
                  :class="{'science-playground-filter__btn--is-active': isActiveItem('tags', allSelectedNameId)}"
                  @click="selectItem('tags', allSelectedNameId)"
                >
                  Все
                </div>
              </div>
              <div
                class="science-playground-filter__tags-item"
                v-for="tag in tags"
                :key="`science-playground-filter__tag-${tag.id}`"
              >
                <div
                  class="science-playground-filter__tag science-playground-filter__btn"
                  :class="{'science-playground-filter__btn--is-active': isActiveItem('tags', tag.id)}"
                  @click="selectItem('tags', tag.id)"
                >
                  #{{ tag.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['breakpoint', 'ymHandler'],
  props: {
    activeFilters: {
      type: Object,
      default: () => ({})
    },
    tabsTitle: {
      type: String,
      default: ''
    },
    tagsTitle: {
      type: String,
      default: ''
    },
    tabs: {
      type: Array,
      default: () => []
    },
    tags: {
      type: Array,
      default: () => []
    },
    enabledYm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allSelectedNameId: '_ALL'
    }
  },
  methods: {
    selectItem(name, id) {
      const isAll = id === this.allSelectedNameId
      const isSelected = this.isActiveItem(name, id)
      const items = isSelected ? this.activeFilters[name].filter(item => item !== id) : [...this.activeFilters[name], id]
      const result = { ...this.activeFilters }
      result[name] = isAll ? [] : items

      this.$emit('filter', result)

      const operation = name === 'theme' ? 'click_content' : 'click_tags';

      if (!this.enabledYm) {
        return
      }

      this.ymHandler({
        code: window.YM_KEY,
        operation,
      })
    },
    isActiveItem(group, id) {
      return (this.activeFilters && this.activeFilters[group].includes(id)) || (id === this.allSelectedNameId && !this.activeFilters[group].length)
    }
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.science-playground-filter';

#{$b} {
  position: relative;
  color: $white-true;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(100% + 88px);
    pointer-events: none;
    touch-action: none;
    background-image: url('/frontend/assets/img/childrens-playground/SciencePlaygroundFilter/bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    @include mobile {
      height: 100%;
      background-image: url('/frontend/assets/img/childrens-playground/SciencePlaygroundFilter/bg-mob.png');
    }
  }

  // .science-playground-filter__inner
  &__inner {
    position: relative;
    z-index: 1;
    padding: 70px 0;

    @include mobile {
      padding: 50px 0 70px;
    }
  }

  // .science-playground-filter__title
  &__title {
    margin-bottom: 17px;

    @include mobile {
      margin-bottom: 24px;
    }
  }

  // .science-playground-filter__tabs
  &__tabs {
    display: flex;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;

    @include removeScrollBar;

    @include mobile {
      margin-left: -#{$container-pd-left-mobile};
      padding-left: $container-pd-left-mobile;
      padding-right: $container-pd-left-mobile;
      width: calc(100% + ($container-pd-left-mobile + $container-pd-right-mobile))
    }
  }

  // .science-playground-filter__btn
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    cursor: pointer;
    transition: $transtion-default;
    color: $white-true;
    border-color: $white-true;
    background-color: transparent;
    white-space: nowrap;

    @include mobile-min {
      &:hover {
        background-color: rgba($white-true, 0.1);
      }
    }

    // .science-playground-filter__btn--is-active
    &--is-active {
      background-color: $white-true !important;
      color: $color-purple !important;
    }
  }

  // .science-playground-filter__tab
  &__tab {
    height: 40px;
    padding: 5px 20px;
    border: 1px solid $white-true;

    &:not(:last-child) {
      border-right: 0;
    }

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  // .science-playground-filter__top
  &__top {
    margin-bottom: 51px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  // .science-playground-filter__tags
  &__tags {

    // .science-playground-filter__tags-inner
    &-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: -4px;
    }

    // .science-playground-filter__tags-item
    &-item {
      padding: 4px;
    }
  }

  // .science-playground-filter__tag
  &__tag {
    border-radius: 10px;
    padding: 8px 16px;
    border: 1px solid $white-true;
  }
}

</style>
