<template>
  <div class="childrens-playground-banner">
    <div class="u-g-container">
      <!-- баннер -->
      <div class="childrens-playground-banner__inner">
        <div class="childrens-playground-banner__info">
          <h1 class="childrens-playground-banner__title title-xxl" ref="title" v-html="bannerTitle"></h1>
          <div class="childrens-playground-banner__text text-lg text-lg--mob-default" ref="text" v-html="bannerText"></div>
        </div>
        <div class="childrens-playground-banner__image-container">
          <img class="childrens-playground-banner__image" ref="image" src="/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/image-1.png" alt="">
        </div>
      </div>
      <!-- описание -->
      <div class="childrens-playground-banner__description">
        <div class="childrens-playground-banner__description-image-container">
          <img class="childrens-playground-banner__description-image" src="/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/image-3.png" alt="">
        </div>
        <div class="childrens-playground-banner__description-info">
          <h2 class="childrens-playground-banner__description-title title-xl" v-html="descriptionTitle"></h2>
          <div class="childrens-playground-banner__description-text text-lg text-lg--mob-default" v-html="descriptionText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bannerTitle: {
      type: String,
      default: ''
    },
    bannerText: {
      type: String,
      default: ''
    },
    descriptionTitle: {
      type: String,
      default: ''
    },
    descriptionText: {
      type: String,
      default: ''
    },
  },
  mounted() {
    const titleNode = this.$refs.title;
    const textNode = this.$refs.text;
    const imageNode = this.$refs.image;

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        let cssVariableName;
        switch (entry.target) {
          case titleNode:
            cssVariableName = '--title-height';
            break;
          case textNode:
            cssVariableName = '--text-height';
            break;
          case imageNode:
            cssVariableName = '--image-height';
            break;
        }
        document.documentElement.style.setProperty(cssVariableName, `${Math.ceil(entry.contentRect.height)}px`);
      });
    });

    resizeObserver.observe(titleNode);
    resizeObserver.observe(textNode);
    resizeObserver.observe(imageNode);
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.childrens-playground-banner';

#{$b} {

  padding-top: calc(var(--header-height) + 24px);

  background: url(/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/bg-banner.svg);
  background-repeat: no-repeat;
  background-size: 100% calc(var(--header-height) + 730px);
  transition: $transtion-default;

  &:not(:last-child) {
    margin-bottom: 100px;

    @include mobile {
      margin-bottom: 48px;
    }
  }

  @include tablet-only {
    padding-top: calc(var(--header-height) + 77px);
    background: url(/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/bg-banner-mobile.svg);
    background-size: 100% calc(var(--header-height) + var(--title-height) + var(--text-height) + 129px);
    background-repeat: no-repeat;
  }

  @include mobile {
    padding-top: calc(var(--header-height) + 40px);
    background: url(/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/bg-banner-mobile.svg);
    background-size: 100% calc(var(--header-height) + var(--title-height) + var(--text-height) + var(--image-height) + 50px);
    background-repeat: no-repeat;
  }

  &__inner {
    color: $white-true;
    display: flex;
    @include tablet-only {
      flex-wrap: wrap;
      position: relative;
    }
    @include mobile {
      flex-wrap: wrap;
    }
  }

  &__info {
    width: 500px;
    flex: none;
    box-sizing: border-box;

    @include tablet-min {

    }

    @include tablet-only {
      width: 100%;
      flex: auto;
    }

    @include mobile {
      width: 100%;
      flex: auto;
    }

  }

  &__title {
    margin: 0 0 24px;
    @include mobile {
      margin: 0 0 16px;
    }
  }

  &__text {
    @include mobile {
      max-width: 510px;
    }

    @include tablet-only {
      width: calc(100% - get-range-calc(360px, 390px, 768px, 1023px));
    }

    & strong {
      font-weight: 700;
    }

    &::after {
      content: "";
      display: block;
      width: 134px;
      height: 129px;
      background: url(/frontend/assets/img/childrens-playground/ChildrensPlaygroundBanner/image-2.png) no-repeat;
      background-size: cover;
      margin-top: 62px;
      margin-left: 11px;

      @include tablet-only {
        margin-top: 35px;
      }

      @include mobile {
        display: none;
      }
    }
  }

  &__image-container {
    flex: auto;
    padding-left: get-range-calc(50px, 0px, 1024px, 1400px);
    margin-bottom: -28px;

    @include tablet-min-only {
      margin-bottom: get-range-calc(27px, -28px, 1024px, 1400px);
    }

    @include tablet-only {
      padding-top: calc(var(--title-height));
      padding-left: 0;
      position: absolute;
      width: 100%;
      margin-bottom: get-range-calc(0px, -54px, 768px, 1023px);
    }

    @include mobile {
      padding-left: 20px;
      padding-right: 18px;
      max-width: 400px;
      margin-bottom: 0;
    }
  }

  &__image {
    position: relative;
    display: block;
    margin-left: auto;
    top: -28px;
    right: 3px;

    @include tablet-min-only {
      top: get-range-calc(27px, -28px, 1024px, 1400px);
    }

    @include tablet-only {
      width: get-range-calc(300px, 400px, 768px, 1023px);
      top: get-range-calc(0px, -54px, 768px, 1023px);
    }

    @include mobile {
      position: static;
      margin-top: 20px;
    }
  }

  &__description {
    display: flex;
    padding-top: 69px;
    justify-content: space-between;

    @include tablet-only {
      flex-direction: column-reverse;
    }
    @include mobile {
      flex-direction: column-reverse;
      padding-top: 63px;
    }
  }

  &__description-image-container {
    width: calc(44.36% - 35.5px);
    padding-left: 51px;

    @include tablet {
      width: auto;
      padding: 24px 48px 0;
      max-width: 400px;
    }
  }

  &__description-image {
    width: 100%;
    position: relative;
    top: -29px;

    @include tablet {
      position: static;
    }
  }

  &__description-info {
    width: calc(55.64% - 35.5px);

    @include tablet {
      width: auto;
    }
  }

  &__description-title {
    margin: 0 0 33px;
    @include mobile {
      margin: 0 0 32px;
    }
  }
}
</style>
