<template>
  <section class="partnership-offer">
    <UFigures
      :items="figures"
      position="static"
      fullWidth
      :isAnimateImg="false"
      startPosition="center bottom"
    >
      <div class="partnership-offer__inner">
        <h3 class="partnership-offer__title tt-uppercase" v-html="title"/>
        <p class="partnership-offer__text" v-html="text" />
        <CustomButton
            class="partnership-offer__btn"
            theme="primary"
            size="md"
            tag="a"
            href="https://forms.yandex.ru/u/64bf9e55068ff08f5f1a7d8e/"
            target="_blank"
        >
          Оставить заявку
        </CustomButton>
      </div>
    </UFigures>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      figures: [
        {
          name: 'f-excursions-subscribe-1',
          image: '/frontend/assets/img/excursions/circle-group-line-2.svg',
          animation: 'fade-in-left'
        },
        {
          name: 'f-excursions-subscribe-mob',
          image: '/frontend/assets/img/excursions/partnership-offer-mob.svg',
          animation: 'fade-in-left'
        },
      ]
    }
  },
}
</script>

<style lang="scss">
@import "/frontend/scss/base/u-includes";

$b: '.partnership-offer';

#{$b} {
  position: relative;
  padding: 24px 32px;
  border-radius: 16px;
  background-color: $black-true;
  color: $white-true;
  overflow: hidden;

  @include mobile {
    padding: 24px;
  }

  // .partnership-offer__inner
  &__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
  }

  // .partnership-offer__title
  &__title {
    margin: 0 0 16px;
    font-family: $font-family-main-head;
    font-weight: 500;
    font-size: 32px;
    line-height: 100%;

    @include mobile {
      margin: 0 0 23px;
      font-size: 28px;
    }
  }

  // .partnership-offer__text
  &__text {
    margin: 0 0 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;

    @include mobile {
      margin: 0 0 25px;
    }
  }

  // .partnership-offer__btn
  &__btn {
    margin: 0 auto 0 0;
    @include mobile-md {
      margin: 0 auto;
    }
  }
}
</style>
