<template>
  <div
    v-if="isVisibleComponent"
    class="custom-video-player"
    :class="`custom-video-player--theme-${theme} custom-video-player--size-${size}`"
  >
    <div
      class="custom-video-player__inner"
      :class="{'custom-video-player__inner--preview-mode': isStoped}"
      :style="getStyleForInner"
      @click="launch"
    >
      <div v-if="isStoped" class="custom-video-player__play">
        <inline-svg class="custom-video-player__play-icon" src="play-icon" />
      </div>
      <div
        class="custom-video-player__inner-bottom"
        :class="{
          'custom-video-player__inner-bottom--video-launched': !isStoped,
          'custom-video-player__inner-bottom--is-hidden': isHideCaption
        }"
      >
        <div class="custom-video-player__capture">
          {{ videoData.text }}
        </div>
      </div>
    </div>
    <video
      v-if="videoData?.video"
      ref="video"
      class="custom-video-player__video"
      :class="{'custom-video-player__video--preview-mode': isStoped }"
      :src="videoData.video"
      @pause="onPause"
      @play="onPlay"
      @ended="onEnded"
      @pointermove="onPointermove"
      @pointerleave="onPointerleave"
    />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    videoId: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      default: 'default', // default || purple
    },
    size: {
      type: String,
      default: 'default', // default || lg
    }
  },
  data() {
    return {
      video: null,
      videoData : {},
      enumState: {
        PLAYING: 'PLAYING',
        PAUSED: 'PAUSED',
        STOPED: 'STOPED',
      },
      state: null,
      timeoutId: null,
      isHideCaption: false,
      isVisibleComponent: true
    }
  },
  computed: {
    isStoped() {
      return this.state === this.enumState.STOPED
    },
    isPaused() {
      return this.state === this.enumState.PAUSED
    },
    getStyleForInner() {
      return this.isStoped && this.videoData?.poster ? `background-image: url('${this.videoData.poster}');` : ''
    },
    isExistsVideo() {
      return !!this.videoData && !!this.videoData?.video
    }
  },
  methods: {
    launch() {
      if (!this.video) {
        throw new Error('launch: Video is not defined')
      }
      if (this.isStoped) {
        this.video.play()
        this.video.controls = true
        return
      }
    },
    onPlay() {
      if (!this.video) {
        throw new Error('play: Video is not defined')
      }
      this.state = this.enumState.PLAYING
      this.hideCaption(3000)
      this.stopAllOtherVideo()
    },
    stopAllOtherVideo() {
      this.$root.$emit('pauseVideo', this._uid)
    },
    pauseVideoFromPlayOtherVideo(uid) {
      if (uid !== this._uid && this.video) {
        this.video.pause()
      }
    },
    onPause() {
      if (!this.video) {
        throw new Error('pause: Video is not defined')
      }
      this.state = this.enumState.PAUSED
      this.showCaption()
    },
    onEnded() {
      this.state = this.enumState.STOPED
      this.showCaption()
    },
    onPointermove() {
      this.showCaption()
      if (this.isPaused) {
        return
      }
      this.hideCaption(3000)
    },
    onPointerleave() {
      if (this.isPaused) {
        return
      }
      this.hideCaption(0)
    },
    setStateCaption(state) {
      this.isHideCaption = state
    },
    showCaption() {
      clearTimeout(this.timeoutId)
      this.setStateCaption(false)
    },
    hideCaption(ms) {
      this.timeoutId = setTimeout(() => {
        this.setStateCaption(true)
      }, ms);
    },
    async getVideoData() {
      const response = await axios.get('/local/ajax/getVideo.php', { params: { id: this.videoId } });

      if (response && response.data && response.data.video) {
        this.videoData = response.data
      } else {
        this.isVisibleComponent = false
      }
    }
  },
  created() {
    this.state = this.enumState.STOPED

    if (this.videoId) {
      this.getVideoData()
    } else {
      this.isVisibleComponent = false
    }

    this.$root.$on('pauseVideo', this.pauseVideoFromPlayOtherVideo)
  },
  mounted() {
    this.$nextTick(() => {
      this.video = this.$refs.video
    })
  },
  beforeDestroy() {
    this.$root.$off('pauseVideo', this.pauseVideoFromPlayOtherVideo)
  }
}
</script>

<style lang="scss">
@import '@/scss/base/u-includes';

$b: '.custom-video-player';

#{$b} {
  display: inline-block;
  position: relative;
  font-family: $font-family-inter;
  border-radius: 16px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;

  @include tablet {
    display: block;
  }

  // .custom-video-player--size-lg
  &--size-lg {
    border-radius: 40px;
    display: block;
    width: 100%;
  }

  // .custom-video-player--theme-purple
  &--theme-purple {
    &:hover {
      @include mobile-min {
        #{$b}__play {
          background-color: $color-purple;
          color: $white-true;
        }
      }
    }
  }

  // .custom-video-player__inner
  &__inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    border-radius: inherit;
    pointer-events: none;
    touch-action: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;

    // .custom-video-player__inner--preview-mode
    &--preview-mode {
      pointer-events: unset;
      touch-action: unset;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba($black-true, 0.3);
        z-index: 1;
        border-radius: inherit;

        #{$b}--theme-purple & {
          background: linear-gradient(0deg, rgba($black-true, 0.30) 0%, rgba($black-true, 0.30) 100%);
          background-size: cover;
        }
      }
    }

    #{$b}--size-lg & {
      width: 100%;
    }

    // .custom-video-player__inner-bottom
    &-bottom {
      position: relative;
      margin-top: auto;
      z-index: 2;
      padding: 0 20px;
      margin-bottom: 24px;
      transition: $transtion-default;

      // .custom-video-player__inner-bottom--video-launched
      &--video-launched {
        margin-bottom: 65px;
      }

      // .custom-video-player__inner-bottom--is-hidden
      &--is-hidden {
        opacity: 0;
      }

      #{$b}--theme-purple #{$b}__inner--preview-mode & {
        opacity: 0;
      }
    }
  }

  // .custom-video-player__capture
  &__capture {
    color: $white-true;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.5;

    @include lineClamp(3);
  }

  // .custom-video-player__play
  &__play {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 48px;
    height: 48px;
    color: $white-true;
    z-index: 2;
    margin-left: 14px;
    margin-top: 16px;
    transition: color 0.3s ease, background-color 0.3s ease;

    #{$b}--theme-purple & {
      position: absolute;
      left: 50%;
      top: 50%;
      background-color: $white-true;
      color: $color-purple;
      border-radius: 50%;
      margin: 0;
      transform: translate(-50%, -50%);
    }

    #{$b}--size-lg & {
      width: 88px;
      height: 88px;
      padding: 22px;

      @include mobile {
        width: 64px;
        height: 64px;
        padding: 12px;
      }
    }

    // .custom-video-player__play-icon
    &-icon {
      padding-top: 6px;
      padding-bottom: 6px;
      width: 100%;
      height: 100%;

      #{$b}--size-lg & {
        margin-left: 6px;
      }
    }
  }

  // .custom-video-player__video
  &__video {
    max-width: 100%;
    max-height: 100vh;
    display: block;

    @include tablet {
      width: 100%;
    }

    #{$b}--size-lg & {
      width: 100%;
    }

    // .custom-video-player__video--preview-mode
    &--preview-mode {
      pointer-events: none;
      touch-action: none;
    }
  }
}
</style>
