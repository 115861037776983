import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/ru'
dayjs.extend(customParseFormat)

const months = [
  'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентрября', 'Октября', 'Ноября', 'Декабря',
]

export const getFormattedArticleDate = (dateStr) => {
  if (!dateStr) {
    return
  }
  const format = "DD.MM.YYYY HH:mm:ss"
  const formatOther = "DD.MM.YYYY"
  const dateStandart = dayjs(dateStr, format)

  const isDateStandart = dateStandart.isValid()
  const date = isDateStandart ? dateStandart :  dayjs(dateStr, formatOther)

  const day = date.get('date')
  const month = months[date.get('month')]
  const year = date.get('year')

  return `${day} ${month} ${year}`
}
