<template>
  <div
    v-if="isVisible"
    class="science-playground-modal"
    :class="`science-playground-modal--size-${settings.size}`"
    @mousedown.self="close"
  >
    <div class="science-playground-modal__container u-g-container">
      <div class="science-playground-modal__inner">
        <CustomButton
          v-if="canChangeModal"
          class="science-playground-modal__change-btn"
          theme="purple"
          size="md"
          @click="changeModal"
        >
          {{ changeBtnText }}
        </CustomButton>
        <div class="science-playground-modal__close" @click="close">
          <inline-svg class="science-playground-modal__close-icon" src="close" />
        </div>
        <div class="science-playground-modal__body">
          <component v-if="contentComponent.name" :is="contentComponent.name" v-bind="contentComponent.props" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    initialSize: {
      type: String,
      default: 'xl' // xl || md
    },
  },
  data() {
    return {
      CONTENT_COMPONENT_DEFAULT: {
        name: '',
        props: {}
      },
      SETTINGS_DEFAULT: {
        size: this.initialSize,
        changeModalOptions: {},
        type: '',
      },
      isVisible: false,
      contentComponent: {},
      settings: {}
    }
  },
  computed: {
    changeBtnText() {
      return this.settings.type === 'video' ? 'Посмотреть галерею' : this.settings.type === 'gallery' ? 'Посмотреть видео' : ''
    },
    canChangeModal() {
      return this.settings && this.settings.changeModalOptions && Object.values(this.settings.changeModalOptions).filter(item => item).length > 0
    },
  },
  methods: {
    close() {
      this.isVisible = false
      this.setDefault()
      this.setBlockedScrollState(false)
    },
    open({ componentData, modalSettings } = {componentData: {}, modalSettings: {}}) {
      this.isVisible = true
      this.passContent(componentData)
      this.passModalSetting(modalSettings)
      this.setBlockedScrollState(true)
    },
    passModalSetting(settings) {
      if (!settings || !Object.keys(settings).length) {
        return
      }

      for (const key in settings) {
        const value = settings[key]

        this.settings[key] = value && typeof value === 'object' ? JSON.parse(JSON.stringify(settings[key])) : settings[key]
      }
    },
    passContent(componentData) {
      this.contentComponent = { ...this.contentComponent, ...componentData }
      this.dialogVisible = true;
    },
    setBlockedScrollState(state) {
      const delta = state ? window.innerWidth - document.documentElement.clientWidth : ''
      document.body.style.paddingRight = state ? `${delta}px` : ''
      document.body.style.overflow = state ? 'hidden' : ''
      this.$nextTick(() => {
        if (!this.$el || !(this.$el instanceof HTMLElement)) {
          return
        }

        this.$el.style.paddingRight = state ? `${delta}px` : ''
      })
    },
    setDefault() {
      this.contentComponent = JSON.parse(JSON.stringify(this.CONTENT_COMPONENT_DEFAULT))
      this.settings = JSON.parse(JSON.stringify(this.SETTINGS_DEFAULT))
    },
    changeModal() {
      if (this.settings.type === 'video') {
        this.passContent(this.settings.changeModalOptions.gallery)
        this.$nextTick(() => {
          this.settings.type = 'gallery'
        })
      }
      if (this.settings.type === 'gallery') {
        this.passContent(this.settings.changeModalOptions.video)
        this.$nextTick(() => {
          this.settings.type = 'video'
        })
      }
    }
  },
  created() {
    this.setDefault()
  },
  mounted() {
    this.$root.$on('playground-modal-open', this.open)
  },
  beforeDestroy() {
    this.$root.$off('playground-modal-open', this.open)
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.science-playground-modal';

#{$b} {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  padding: 40px 0 80px;
  background: rgba($black-true, 0.6);

  @include removeScrollBar();

  @include tablet {
    padding: 32px 0 60px;
  }

  @include mobile {
    padding: 16px 0 40px 0;
  }

  // .science-playground-modal--size-xl
  &--size-xl {

  }

  // .science-playground-modal--size-md
  &--size-md {

  }

  // .science-playground-modal__container
  &__container {
    margin: auto !important;
    height: 100%;
  }

  // .science-playground-modal__change-btn
  &__change-btn {
    left: 32px;

    @include mobile {
      left: 0;
    }
  }

  &__change-btn,
  &__close {
    position: absolute;
    top: 32px;
    z-index: 2;

    @include mobile {
      top: 0;
    }
  }

  // .science-playground-modal__close
  &__close {
    right: 32px;
    width: 64px;
    height: 64px;
    background-color: $white-true;
    border-radius: 50%;
    padding: 18px;
    color: $color-purple;
    cursor: pointer;
    transition: background-color 0.3s ease;

    @include mobile-min {
      &:hover {
        background-color: $color-purple;
        color: $white-true;

        #{$b}__close-icon {
          transform: rotate(90deg);
        }
      }
    }

    @include mobile {
      right: 0;
      width: 48px;
      height: 48px;
      padding: 14px;
    }

    // .science-playground-modal__close-icon
    &-icon {
      transition: $transtion-default;
    }
  }

  // .science-playground-modal__inner
  &__inner {
    position: relative;
    height: 100%;

    @include mobile {
      padding-top: 50px;
    }
  }

  // .science-playground-modal__body
  &__body {
    height: 100%;
  }
}
</style>
