<template>
  <div class="u-filtered-cards">
    <UFigures
      :items="[
        {'name':'f-filtered-cards-page-1','image':'/frontend/assets/img/excursions/tall-circle-group-line.svg','animation':'fade-in-left'},
      ]"
      fullWidth
      position="static"
    >
      <div class="u-filtered-cards__wrapper">
        <UFilteredCardsFilter
          ref="filter"
          class="u-filtered-cards__filter"
          :initialFilters="params.initialFilters"
          :options="getInitParams"
          :ignoreRegionFilter="ignoreRegionFilter"
          @change="onFilterChange"
        >
          <slot name="text"></slot>
        </UFilteredCardsFilter>
        <div class="u-filtered-cards__body">
          <UFilteredCardsList
            v-if="items.length"
            :items="items"
            ref="cardsList"
            isLinks
            :containerClass="listContainerClass"
          >
            <template v-slot="{ id, card }">
              <slot :id="id" :card="card"></slot>
            </template>
          </UFilteredCardsList>
          <NotFound v-else text="По данным параметрам ничего не найдено" />
          <div class="u-filtered-cards__load-more" v-show="paginator.NavPageNomer < paginator.NavPageCount">
            <CustomButton
              theme="primary"
              size="md"
              @click="showNewCards(false)"
            >
              Показать ещё
            </CustomButton>
          </div>
        </div>
      </div>
    </UFigures>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import cloneDeep from 'lodash-es/cloneDeep'

export default {
  name: 'FilteredCards',
  props: {
    url: {
      type: String,
      required: true
    },
    initParams: {
      type: Object,
    },
    initItems: {
      type: Array,
      default: () => []
    },
    initNextUrl: {
      type: String,
      default: ""
    },
    initPaginator: {
      type: Object,
      default: () => {}
    },
    listContainerClass: {
      type: String,
      default: null
    },
    urlPrefix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      filterState: {},
      items: this.initItems,
      nextUrl: this.initNextUrl,
      paginator: this.initPaginator,
      params: cloneDeep(this.initParams),
      ignoreRegionFilter: false
    };
  },
  computed: {
    getInitParams() {
      let optionsSorted = {};
      for (let p in this.params) {
        if (
          p != 'initialFilters'
            && !(p === 'ageGroup' && this.filterState.filter === 'past')
            && !(p === 'year' && this.filterState.filter === 'upcoming')
        ) {
          optionsSorted[p] = this.params[p]
        }
      }
      return optionsSorted;
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).locale("ru").format("DD MMMM / HH:mm")
    },
    onFilterChange(filterState, withLoad) {
      this.filterState = filterState;

      if (withLoad) {
        this.loadFirstPage(true);
      }
    },
    loadFirstPage() {
      const lines = [];
      if (this.filterState.filter) {
        lines.push(`filter=${this.filterState.filter}`);
      }

      for (let p in this.getInitParams) {
        if (this.filterState[p]) {
          lines.push(`${p}=${this.filterState[p]}`);
        }
      }

      if (this.filterState.month.length && this.filterState.filter !== 'past') {
        lines.push(this.filterState.month.map(id => `months[]=${id}`).join('&'));
      }
      const params = lines.join("&");

      this.nextUrl = `${this.urlPrefix}?AJAX=Y&PAGEN_${this.paginator.NavNum}=1&${params}`;
      this.loadMoreItems(true);
    },
    async loadMoreItems(clearItems) {
      try {
        this.$root.$emit('setLoading', true)
        const response = await axios.get(this.nextUrl);
        this.$root.$emit('setLoading', false)

        if (!response || !response.data) {
          return
        }

        this.nextUrl = response.data.NEXT_URL;
        this.paginator = response.data.PAGINATOR;

        if (clearItems) {
          this.items = [];
        }

        this.items = [...this.items, ...response.data.ITEMS]
        this.params = { ...this.params, year: response.data.DATE, region: response.data.REGIONS }
        this.ignoreRegionFilter = Boolean(response.data.IGNORE_REGION)

        if (this.ignoreRegionFilter) {
          this.$refs.filter.updateInitialSavedRegion()
          this.$refs.filter.updateSelectedRegion('', true)
        } else {
          this.$refs.filter.updateRegionBySaved()
        }
      } catch (e) {
        console.warn('UFilteredCards loadMoreItems:', e)
      }
    },
    async showNewCards(clearItems){
      await this.loadMoreItems(clearItems)
    },
  }
};
</script>

<style lang="scss">
@import "@/scss/base/u-includes";

$b: '.u-filtered-cards';
$offsetTop: 47px;

#{$b} {
  display: block;

  &-section {
    position: relative;
    z-index: 4;
    padding-top: 0;
    padding-bottom: 129px;

    @include mobile {
      padding-bottom: 64px;
    }

  }

  // .u-filtered-cards__wrapper
  &__wrapper {
    position: relative;
    z-index: 10;
  }

  &__filter {
    transform: translateY(-$offsetTop);
    margin-bottom: -$offsetTop;
  }

  &__body {
    margin-top: 40px;

    @include mobile {
      margin-top: 24px;
    }
  }

  &__load-more {
    margin-top: 32px;

    @include tablet {
      text-align: center;
    }
  }
}
</style>
