<template>
  <div class="u-excursions">
    <UExcursionCard
      v-for="(card,i) in items"
      :key="`u-excursions__card-${i}`"
      class="u-excursions__card"
      :data="card"
      :categoryOptions="categoryOptions"
    />
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
  }
}
</script>

<style lang="scss">
@import '/frontend/scss/base/u-includes';

$b: '.u-excursions';

#{$b} {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;

  @include mobile {
    display: block;
  }

  // .u-excursions__card
  &__card {
    @include mobile {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
